:root {
  --color-im-dark-blue: #14477d;
  --color-im-dark-blue-100: #1c75bc0a;
  --color-primary: #277fd8;
  --color-im-dark-blue-200: #93aac3;
  --color-im-mid-blue: #1b75bc;
  --color-im-mid-blue-100: #b8d4ea;
  --color-im-light-blue: #6dcff6;
  --color-im-light-blue-100: #d2f0fc;
  --color-im-grey: #58595b;
  --color-im-grey-50: #eeeeef;
  --color-im-grey-80: #58595bcc;
  --color-im-grey-100: #cbcccc;
  --color-im-grey-300: #8f9091;
  --color-im-grey-400: #797a7c;
  --color-im-grey-38: #616161;
  --color-im-grey-900: #252526;
  --color-im-teal: #00a88e;
  --color-im-yellow: #fd0;
  --color-im-orange: #f7931e;
  --color-im-purple: #8a0d71;
  --color-im-red: #ed1c24;
  --color-im-green: #8dc13f;
  --color-white: #fff;
  --color-white-100: #f5f5f5;
  --color-black: #000;
  --color-alt-blue: #2e68a5;
  --color-background-1: #f7f7fe;
  --color-background-2: #ebf0f8;
  --color-background-3: #023459;
  --color-light-grey: #ccc;
  --color-light-gray: #ccc;
  --color-dark-text: #353637;
  --color-disabled-gray: #fff6;
  --chart-harmonious-blue-900: #1a325d;
  --color-im-dark-blue-500: #14477d;
  --color-im-error-fill: #fdeded;
  --color-im-error-content: #5f2120;
  --color-im-warning-fill: #fff4e5;
  --color-im-warning-content: #663c00;
  --color-im-info-fill: #e5f6fd;
  --color-im-info-content: #014361;
  --color-im-success-fill: #edf7ed;
  --color-im-success-content: #1e4620;
  --color-im-mid-green: #dcecc3;
  --color-im-light-red: #fde8e9;
  --color-im-light-blue-50: #f0fafe;
  --color-add-ons: #00c000;
  --color-specials: #ff0000;
  --color-reruns: #0100fe;
  --color-dr: #7f007f;
  --color-destruction: #ff80ff;
  --color-im-info-outlined: #1462ad;
  --color-om-scroll-track: #f1f1f1;
  --color-om-scroll-thumb: #888888;
  --color-om-scroll-thumb-hover: #555555;
  --color-lavender: #e6e6fa;
  --color-im-transparent: #ffffff00;
  --color-dashboard-text: #1e417a;
  --color-system-request-selected: #3f51b5;
  --color-system-request-not-selected: #ddd;
  --color-todo-highpriority: #d50000;
  --color-todo-requestruns: #8dc13f;
  --color-todo-customer: #1b75bc;
  --color-todo-containers: #9e9e9e;
  --color-todo-openmedia: #ab47bc;
  --color-todo-discrepacy: #f9a825;
  --color-paper: #1e417a;
  --color-nonselactable: #cbcccc;
  --color-selectable: #fff4b0;
  --color-btnSelected: #14477d;
  --color-light-orange: #fddeb9;
}
