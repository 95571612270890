/* Inter Font - Weight 400 */
@font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

/* Inter Font - Weight 500 */
@font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

/* Inter Font - Weight 700 */
@font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/* Roboto Font - Weight 400 */
@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}