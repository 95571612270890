@import url("./font.css");
@import url("./variables.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Inter", Arial, sans-serif;
  background-repeat: no-repeat;
  background-position: right;
}
