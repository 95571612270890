.tree-container {
  height: calc(70vh - 30px);
  overflow: auto;
  padding: 6px 12px;
  border-top: 1px solid var(--color-im-grey-100);
}
.unassigned-tree-container {
  height: calc(70vh - 50px);
  overflow: auto;
  padding: 6px 24px;
  background-color: var(--color-im-light-blue-50);
  border: 1px solid var(--color-im-grey-100);
}
.tree-container li,
.unassigned-tree-container li {
  list-style: none;
}
.root-node {
  padding: 5px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.root-node.selected {
  background-color: var(--color-im-light-blue-100);
}
.root-node:hover:not(.root-node.selected) {
  background-color: var(--color-im-grey-50);
}
.arrow-icon {
  padding-right: 10px;
}
.node-text {
  padding-left: 10px;
}
.node-text.selected {
  color: var(--color-im-orange);
}
